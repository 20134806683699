<template>
  <b-card
    no-body
    class="card-payment"
  >
    <b-card-body>
      <!-- form -->
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Date"
              label-for="date"
              class="mb-2"
            >
              <flat-pickr
                v-model="data.date"
                class="form-control"
                :config="{ dateFormat: 'd/m/Y', enableTime: false }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Taxi List"
              label-for="taxi-list"
            >
              <v-select
                :clearable="false"
                :options="taxiList"
                :disabled="!taxiEnable"
                input-id="taxi-list"
                label="licenceNum"
                multiple
                :reduce="(taxi) => taxi.id"
                v-model="data.taxiIds"
              >
                <template #option="{ firstName, lastName, licenceNum }">
                  <span>
                    <span class="font-weight-bolder">{{ licenceNum }}</span>
                    -
                    <small>{{ `${firstName} ${lastName}` }}</small>
                  </span>
                </template>

                <template #selected-option="{ firstName, lastName, licenceNum }">
                  <span>
                    <span class="font-weight-bolder">{{ licenceNum }}</span>
                    -
                    <small>{{ `${firstName} ${lastName}` }}</small>
                  </span>
                </template>
              </v-select>
              <b-form-checkbox
                v-model="taxiEnable"
                @change="(value) => taxiEnableChange(value)"
                name="check-button"
                class="mt-25"
                switch
                inline
              >
                Enable
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Account List"
              label-for="account-list"
            >
              <v-select
                :clearable="false"
                :options="accountList"
                :disabled="!accountEnable"
                input-id="account-list"
                label="name"
                multiple
                :reduce="(account) => account.id"
                v-model="data.accountIds"
              >
                <template #option="{ name }">
                  <span class="font-weight-bolder">{{ name }}</span>
                </template>

                <template #selected-option="{ name }">
                  <span class="font-weight-bolder">{{ name }}</span>
                </template>
              </v-select>

              <b-form-checkbox
                v-model="accountEnable"
                @change="(value) => accountEnableChange(value)"
                name="check-button"
                class="mt-25"
                switch
                inline
              >
                Enable
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Company List"
              label-for="company-list"
            >
              <v-select
                :clearable="false"
                :options="companyList"
                :disabled="!companyEnable"
                input-id="company-list"
                label="name"
                multiple
                :reduce="(company) => company.id"
                v-model="data.companyIds"
              >
                <template #option="{ name }">
                  <span class="font-weight-bolder">
                    {{ name }}
                  </span>
                </template>

                <template #selected-option="{ name }">
                  <span class="font-weight-bolder">
                    {{ name }}
                  </span>
                </template>
              </v-select>

              <b-form-checkbox
                v-model="companyEnable"
                @change="(value) => companyEnableChange(value)"
                name="check-button"
                class="mt-25"
                switch
                inline
              >
                Enable
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              block
              variant="primary"
              @click="getBalances()"
            >
              Get Balances
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BFormCheckbox
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api';
import useStatistic from '@/modules/statistic/composables/useStatistic';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component'
import useTaxi from '@/modules/taxi/composables/useTaxi';
import useAccount from '@/modules/account/composables/useAccount';
import useCompany from '@/modules/company/composables/useCompany';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { getTaxiList } = useTaxi();
    const { getAccountList } = useAccount();
    const { getCompanyList } = useCompany();
    const { getBalanceList } = useStatistic();
    const { convertDateToUTC } = useUtils();

    const isAddNewBalanceSidebarActive = ref(false);
    const data = ref({ date: new Date(), taxiIds: null, companyIds: null, accountIds: null });
    const taxiList = ref([]);
    const taxiEnable = ref(false)
    const accountList = ref([]);
    const accountEnable = ref(false)
    const companyList = ref([]);
    const companyEnable = ref(false)

    const taxiEnableChange = (value) => { data.value.taxiIds = (value) ? [] : null; }
    const accountEnableChange = (value) => { data.value.accountIds = (value) ? [] : null; }
    const companyEnableChange = (value) => { data.value.companyIds = (value) ? [] : null; }

    const initData = async () => {
      const { taxis } = await getTaxiList();
      const { accounts } = await getAccountList();
      const { companies } = await getCompanyList();
      companyList.value = companies;
      accountList.value = accounts;
      taxiList.value = taxis;
    }

    const getBalances = async () => {
      const params = { ...data.value, date: convertDateToUTC(data.value.date) }
      const response = await getBalanceList(params);

      if (response !== null) {
        // create file link in browser's memory
        const url = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    }

    initData();

    return {
      // Reactive
      data,
      isAddNewBalanceSidebarActive,
      taxiList,
      accountList,
      companyList,
      taxiEnable,
      accountEnable,
      companyEnable,

      // Method
      getBalances,
      taxiEnableChange,
      accountEnableChange,
      companyEnableChange,
    };
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormCheckbox,
    flatPickr,
    vSelect,
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

#balance-table {
  min-height: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>